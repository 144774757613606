import React from 'react';
import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-dark.css';
import classes from './CodeHighlight.module.scss';

const CopyCodeButton = React.memo<{ code: string }>(function CopyButtonFC({ code }) {
    const timerId = React.useRef<NodeJS.Timeout | null>(null);
    const [isCopied, setIsCopied] = React.useState(false);

    React.useEffect(() => {
        return () => {
            if (timerId.current) {
                clearTimeout(timerId.current);
            }
        };
    }, []);

    const handleCopyToClipboard = () => {
        if (!code) return;
        navigator.clipboard
            .writeText(code)
            .then(() => {
                setIsCopied(true);
                timerId.current = setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch(console.log);
    };

    return (
        <div className={classes['code-actions-wrapper']}>
            <SlButton onClick={handleCopyToClipboard} variant="text" className={classes['action-button']}>
                <Icon
                    icon={isCopied ? 'lets-icons:check-ring-light' : 'lets-icons:copy-light'}
                    width="20px"
                    height="20px"
                />
                <span>{isCopied ? 'Copied' : 'Copy'}</span>
            </SlButton>
        </div>
    );
});

interface CodeHighlightProps {
    content: string;
    isCodeCompleted: boolean;
}

const CodeHighlight: React.FC<CodeHighlightProps> = ({ content, isCodeCompleted }) => {
    const codeRef = React.useRef(null);

    const [language, setLanguage] = React.useState('');
    const [code, setCode] = React.useState('');

    React.useEffect(() => {
        if (isCodeCompleted && codeRef.current) {
            const [codeLanguage, ...rest] = content.split('\n');
            setLanguage(codeLanguage);
            setCode(rest.join('\n'));
        }
    }, [isCodeCompleted, content]);

    React.useEffect(() => {
        if (code && codeRef.current) {
            hljs.highlightElement(codeRef.current);
        }
    }, [code]);

    return (
        <div className={classes['block-container']}>
            <div className={classes['block-header']}>
                <span>{language}</span>
                <CopyCodeButton code={code} />
            </div>
            <pre className={classes['code-block-container']}>
                <code ref={codeRef} className={language}>
                    {code}
                </code>
            </pre>
        </div>
    );
};

export default CodeHighlight;

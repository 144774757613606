import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import { getIconName } from '../helpers';
import { DocumentStatus } from '../../../redux/features/conversations/ChatState';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';

import classes from './ChatHistoryDocumentContent.module.scss';
import tooltipStyles from '../../global/tooltip.module.scss';

interface ChatHistoryDocumentContentProps {
    messageId?: string;
    messageContent: string;
}

interface DocumentData {
    name: string;
    size: string;
    status: DocumentStatus;
    // we need to keep isUploaded and isDeleted until all message documents will be updated with statuses
    isUploaded?: boolean;
    isDeleted?: boolean;
    tooltipMessage?: string;
}

interface DocumentMessageContent {
    documents: DocumentData[];
}

// old message documents don't have statuses so we need workaround for compatibility with previous versions
const updateDocWithStatusForCompatibility = (d: DocumentData): DocumentData => ({
    ...d,
    status:
        d.status ||
        (d.isDeleted && DocumentStatus.Deleted) ||
        (d.isUploaded && DocumentStatus.Succeeded) ||
        DocumentStatus.Failed,
    tooltipMessage:
        d.status === DocumentStatus.Processing && d.size.includes('MB')
            ? 'Please wait. Loading may take longer for larger documents.'
            : d.tooltipMessage,
});

const ChatHistoryDocumentContent: React.FC<ChatHistoryDocumentContentProps> = ({ messageId, messageContent }) => {
    let documents: DocumentData[] = [];
    try {
        const message = JSON.parse(messageContent) as DocumentMessageContent;
        documents = message.documents.map(updateDocWithStatusForCompatibility);
    } catch (e) {
        console.error('Error parsing chat history file item: ' + messageContent);
    }

    const DocumentStatusFC: React.FC<{ status: string }> = ({ status }) => {
        const isSuccess = status === 'Success';
        const isProcessing = status === 'Processing';
        const isDeleted = status === 'Deleted';

        return (
            <div
                className={`${classes.status} ${
                    (isProcessing && classes['status-processing']) ||
                    (isSuccess && classes['status-success']) ||
                    (isDeleted && classes['status-deleted']) ||
                    classes['status-error']
                }`}
            >
                {(isDeleted && 'Removed') || (isProcessing && 'In process...') || (isSuccess && 'Success') || 'Fail'}
            </div>
        );
    };

    return (
        <>
            {documents.map(({ name, size, status, tooltipMessage }) => (
                <div className={classes.file} key={`${messageId || 'noMsgId'}_${uuidv4()}`}>
                    <SlIcon name={getIconName(name)} className={classes.icon}></SlIcon>
                    <div>
                        <div className={classes.name}>{name} </div>
                        <div className={classes.size}>{size}</div>
                    </div>
                    <div className={classes.info}>
                        {tooltipMessage && (
                            <SlTooltip content={tooltipMessage} className={tooltipStyles['sapience-tooltip']}>
                                <InfoIcon
                                    className={`${classes['info-icon']} ${
                                        status === DocumentStatus.Failed ? classes['info-icon-red'] : ''
                                    }`}
                                />
                            </SlTooltip>
                        )}
                        <DocumentStatusFC status={status} />
                    </div>
                </div>
            ))}
        </>
    );
};

export default React.memo(ChatHistoryDocumentContent);

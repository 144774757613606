import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTrigger,
} from '@fluentui/react-components';
import { Icon } from '@iconify/react';
import React, { FC, useEffect, useState } from 'react';

import { useButtonClasses } from '../../styles/global/button';
import { usePopUpClasses } from '../../styles/popups/popUpDialog';

interface PopUpProps {
    header: string;
    content: string;
    buttonText: string;
    onAction: () => void;
    children: React.ReactNode;
    canHide: boolean;
}

export const PopUpDialog: FC<PopUpProps> = ({ header, content, buttonText, onAction, children, canHide }) => {
    const buttonClasses = useButtonClasses();
    const classes = usePopUpClasses();
    const [isDoNotShowAgain, setIsDoNotShowAgain] = useState(false);

    const onTrigger = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        event.preventDefault();
        if (isDoNotShowAgain) {
            onAction();
        }
    };

    const handleCheckBoxChange = () => {
        setIsDoNotShowAgain(!isDoNotShowAgain);
    };

    useEffect(() => {
        const isDoNotShowAgain = localStorage.getItem(header + '-isDoNotShowAgain');
        if (isDoNotShowAgain == 'true') {
            setIsDoNotShowAgain(true);
        } else {
            setIsDoNotShowAgain(false);
        }
    }, []);

    // Ref needed to close the dialog
    const dialogRef = React.useRef(null);

    const WarningIcon: FC = () => {
        const color = canHide ? 'var(--core-jade)' : 'var(--status-error)';
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="9" stroke={color} />
                <path
                    d="M12.5 16.5C12.5 16.7761 12.2761 17 12 17C11.7239 17 11.5 16.7761 11.5 16.5C11.5 16.2239 11.7239 16 12 16C12.2761 16 12.5 16.2239 12.5 16.5Z"
                    fill="black"
                />
                <path d="M12 14V7" stroke="black" />
            </svg>
        );
    };

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <div onMouseDown={onTrigger} style={{ width: 'fit-content', height: 'fit-content' }}>
                    {children}
                </div>
            </DialogTrigger>
            <DialogSurface className={classes.root} ref={dialogRef}>
                <DialogBody className={classes.wrapper}>
                    <div className={classes.headerwrapper}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <WarningIcon />
                            <h3>{header}</h3>
                        </div>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Icon
                                icon="lets-icons:close-round-light"
                                width="20px"
                                height="20px"
                                className={classes.closebutton}
                            />
                        </DialogTrigger>
                    </div>
                    <DialogContent className={classes.content}>{content}</DialogContent>
                    <DialogActions
                        className={classes.actions}
                        style={{ justifyContent: canHide ? 'space-between' : 'flex-end' }}
                    >
                        <Checkbox
                            label="Do not show again"
                            onChange={handleCheckBoxChange}
                            style={{ display: canHide ? 'flex' : 'none' }}
                        />
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button
                                    className={buttonClasses.secondaryButton}
                                    onClick={() => {
                                        localStorage.setItem(
                                            header + '-isDoNotShowAgain',
                                            isDoNotShowAgain ? 'true' : 'false',
                                        );
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogTrigger>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    className={buttonClasses.primaryButton}
                                    onClick={() => {
                                        onAction();
                                        localStorage.setItem(
                                            header + '-isDoNotShowAgain',
                                            isDoNotShowAgain ? 'true' : 'false',
                                        );
                                    }}
                                >
                                    {buttonText}
                                </Button>
                            </DialogTrigger>
                        </div>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

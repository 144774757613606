import {
    createTableColumn,
    TableCell,
    TableCellLayout,
    TableColumnDefinition,
    TableColumnId,
    TableHeaderCell,
    TableHeaderCellProps,
    useTableFeatures,
    useTableSort,
} from '@fluentui/react-components';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import * as React from 'react';

import { IMetadata } from '../../libs/models/ChatMessage';
import { DocumentStatus, IDocumentWithKey } from '../../redux/features/conversations/ChatState';
import { timestampToDateString } from '../utils/TextUtils';
import TagSelector from './TagSelector';
import { formatFileSize, getIconName } from './helpers';

import classes from './DocumentsTable.module.scss';
import tooltipStyles from '../global/tooltip.module.scss';

export interface TableItem {
    key: string;
    id: string;
    chatId: string;
    name: {
        label: string;
        icon: string;
        url?: string;
    };
    createdOn: {
        label: string;
        timestamp: number;
    };
    size: number;
    status: DocumentStatus;
    message?: string;
    metadata?: IMetadata;
}

export type IDocumentHandler = (documentId: string) => () => void;

const ProgressBox: React.FC<{ state: DocumentStatus; message: string }> = ({ state, message }) => {
    let style: Record<string, string> = { borderColor: '#CDD3D8' };
    let text = String(state);
    if (state === DocumentStatus.Uploading) {
        text = 'Loading...';
    } else if (state === DocumentStatus.Deleting) {
        text = 'Deleting...';
    } else if (state === DocumentStatus.Succeeded) {
        text = 'Success';
        style = { borderColor: 'var(--core-Jade, #37E17B)' };
    } else if (state === DocumentStatus.Failed) {
        text = 'Failed';
        style = { borderColor: 'var(--status-error, #FF6C6C)' };
    }

    return (
        <div className={classes.progress}>
            <div className={classes['progress-state']} style={style}>
                {text}
            </div>
            {state === DocumentStatus.Failed && (
                <SlTooltip content={message} className={tooltipStyles['sapience-tooltip']}>
                    <img src="/info.svg" />
                </SlTooltip>
            )}
        </div>
    );
};

export default function useTable(
    resources: IDocumentWithKey[],
    deletingDocuments: string[],
    getDeleteHandler: IDocumentHandler,
) {
    const headerSortProps = (columnId: TableColumnId): TableHeaderCellProps => ({
        onClick: (e: React.MouseEvent) => {
            toggleColumnSort(e, columnId);
        },
        sortDirection: getSortDirection(columnId),
    });

    const columns: Array<TableColumnDefinition<TableItem>> = [
        createTableColumn<TableItem>({
            columnId: 'name',
            renderHeaderCell: () => (
                <TableHeaderCell key="name" {...headerSortProps('name')} className={classes['th-name']}>
                    Name
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={item.key}>
                    <TableCellLayout media={<SlIcon slot="prefix" name={item.name.icon}></SlIcon>} truncate>
                        <a href={item.name.url} target="_blank" rel="noopener noreferrer" title={item.name.label}>
                            {item.name.label}
                        </a>
                    </TableCellLayout>
                </TableCell>
            ),
            compare: (a, b) => {
                const comparison = a.name.label.localeCompare(b.name.label);
                return getSortDirection('name') === 'ascending' ? comparison : comparison * -1;
            },
        }),
        createTableColumn<TableItem>({
            columnId: 'fileSize',
            renderHeaderCell: () => (
                <TableHeaderCell key="fileSize" {...headerSortProps('fileSize')}>
                    Size
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                // <TableCell key={`${item.key}-size`}>{item.size ? item.size.toLocaleString() : 'N/A'}</TableCell>
                <TableCell key={`${item.key}-size`}>{formatFileSize(item.size)}</TableCell>
            ),
            compare: (a, b) => {
                const comparison = a.size > b.size ? 1 : -1;
                return getSortDirection('fileSize') === 'ascending' ? comparison : comparison * -1;
            },
        }),
        ...(localStorage.getItem('SHOW_TAG') === 'true'
            ? [
                  createTableColumn<TableItem>({
                      columnId: 'tag',
                      renderHeaderCell: () => (
                          <TableHeaderCell key="tag" {...headerSortProps('tag')} className={classes['th-tag']}>
                              Tag
                          </TableHeaderCell>
                      ),
                      renderCell: (item) => (
                          <TableCell key={`${item.key}-tag`}>
                              <TagSelector
                                  metadata={item.metadata ?? {}}
                                  chatId={item.chatId}
                                  documentId={item.id}
                              ></TagSelector>
                          </TableCell>
                      ),
                      compare: (a, b) => {
                          const comparison = String(a.metadata?.isInternal).localeCompare(
                              String(b.metadata?.isInternal),
                          );
                          return getSortDirection('access') === 'ascending' ? comparison : comparison * -1;
                      },
                  }),
              ]
            : []),
        createTableColumn<TableItem>({
            columnId: 'progress',
            renderHeaderCell: () => (
                <TableHeaderCell key="progress" {...headerSortProps('progress')}>
                    Progress
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={`${item.key}-progress`}>
                    <ProgressBox state={item.status} message={item.message ?? ''} />
                </TableCell>
            ),
            compare: (a, b) => {
                const comparison = String(a.status).localeCompare(String(b.status));
                return getSortDirection('name') === 'ascending' ? comparison : comparison * -1;
            },
        }),
        createTableColumn<TableItem>({
            columnId: 'delete',
            renderHeaderCell: () => <TableHeaderCell key="delete"> </TableHeaderCell>,
            renderCell: (item) => (
                <TableCell key={`${item.key}-delete`}>
                    <SlButton
                        variant="text"
                        className={classes['delete-button']}
                        disabled={!item.id || deletingDocuments.includes(item.id)}
                        onClick={getDeleteHandler(item.id)}
                    >
                        <SlIcon slot="prefix" name="trash" className={classes['delete-button-icon']}></SlIcon>
                        Delete
                    </SlButton>
                </TableCell>
            ),
        }),
    ];

    const items = resources.map(
        ({ key, id, chatId, name, hyperlink, createdOn, size, metadata, status, message, deleteHandler }) => ({
            key,
            id,
            chatId,
            name: {
                label: name,
                icon: getIconName(name),
                url: hyperlink || (id && `${chatId}/documents/${id}`) || undefined,
            },
            createdOn: {
                label: timestampToDateString(createdOn),
                timestamp: createdOn,
            },
            size,
            metadata: metadata as IMetadata,
            status,
            message,
            deleteHandler,
        }),
    );

    const {
        sort: { getSortDirection, toggleColumnSort, sortColumn },
    } = useTableFeatures(
        {
            columns,
            items,
        },
        [
            useTableSort({
                defaultSortState: { sortColumn: 'createdOn', sortDirection: 'descending' },
            }),
        ],
    );

    if (sortColumn) {
        items.sort((a, b) => {
            const compare = columns.find((column) => column.columnId === sortColumn)?.compare;
            return compare?.(a, b) ?? 0;
        });
    }

    return { columns, rows: items };
}

import { GPTServiceId } from '../../components/utils/GPTModels';
import { IAssistant } from '../models/Assistants';
import { BaseService } from './BaseService';

interface AssistantResponse {
    assistant: IAssistant;
}

export class AssistantService extends BaseService {
    private static instance: AssistantService;

    private constructor() {
        super();
    }

    // Static method to provide global point of access
    public static getInstance(): AssistantService {
        if (!AssistantService.instance) {
            AssistantService.instance = new AssistantService();
        }
        return AssistantService.instance;
    }

    createAssistantAsync = async (
        title: string,
        systemDescription: string,
        responseTemperature: number,
        serviceId: GPTServiceId,
        accessToken: string,
    ): Promise<IAssistant> => {
        const body = {
            title,
            systemDescription,
            responseTemperature,
            serviceId,
        };

        const result = await this.getResponseAsync<AssistantResponse>(
            {
                commandPath: 'assistants',
                method: 'POST',
                body,
            },
            accessToken,
        );

        return result.assistant;
    };

    getAllAssistantsAsync = async (accessToken: string): Promise<IAssistant[]> => {
        const result = await this.getResponseAsync<IAssistant[]>(
            {
                commandPath: 'assistants',
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };

    editAssistantAsync = async (
        title: string,
        systemDescription: string,
        responseTemperature: number,
        id: string,
        active: boolean,
        pinned: boolean,
        serviceId: GPTServiceId,
        accessToken: string,
    ): Promise<IAssistant> => {
        const body = {
            title,
            systemDescription,
            responseTemperature,
            active,
            pinned,
            serviceId,
        };

        const result = await this.getResponseAsync<IAssistant>(
            {
                commandPath: `assistants/${id}`,
                method: 'PATCH',
                body,
            },
            accessToken,
        );

        return result;
    };

    deleteAssistantAsync = async (id: string, accessToken: string): Promise<void> => {
        await this.getResponseAsync(
            {
                commandPath: `assistants/${id}`,
                method: 'DELETE',
            },
            accessToken,
        );
    };
}

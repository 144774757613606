import { Button, Tooltip } from '@fluentui/react-components';
import React from 'react';

import { Icon } from '@iconify/react';

export const markdownToPlainText = (markdownText: string): string => {
    // Remove headers
    let text = markdownText.replace(/#+\s+(.*)/g, '$1');

    // Remove bold/italic
    text = text
        .replace(/\*\*(.*?)\*\*/g, '$1') // Bold
        .replace(/\*(.*?)\*/g, '$1') // Italic
        .replace(/__(.*?)__/g, '$1') // Bold
        .replace(/_(.*?)_/g, '$1'); // Italic

    // Remove links
    text = text.replace(/\[(.*?)\]\(.*?\)/g, '$1');

    // Remove images
    text = text.replace(/!\[(.*?)\]\(.*?\)/g, '$1');

    // Convert lists to plain text
    text = text.replace(/^\s*[-*+]\s+/gm, '• ');
    //text = text.replace(/^\s*(\d+)\.\s+/gm, '$1 ');

    // Remove code blocks
    text = text.replace(/```[\s\S]*?```/g, '');
    text = text.replace(/`(.*?)`/g, '$1');

    // Remove horizontal rules
    text = text.replace(/\n\s*[-*_]{3,}\s*\n/g, '\n\n');

    // Clean up extra whitespace
    text = text
        .replace(/\n\s+\n/g, '\n\n')
        .replace(/ +/g, ' ')
        .trim();

    return text;
};

interface CopyButtonProps {
    messageContent: string;
}
const CopyButton: React.FC<CopyButtonProps> = ({ messageContent }) => {
    const [messagedCopied, setMessageCopied] = React.useState(false);

    React.useEffect(() => {
        setMessageCopied(false);
    }, [messageContent]);

    const copyOnClick = () => {
        void navigator.clipboard
            .writeText(markdownToPlainText(messageContent))
            .then(() => {
                setMessageCopied(true);
            })
            .then(() => {
                setTimeout(() => {
                    setMessageCopied(false);
                }, 2000);
            });
    };

    return (
        <Tooltip content={messagedCopied ? 'Copied' : 'Copy text'} relationship="label">
            <Button
                icon={<Icon icon="lets-icons:copy-light" color={messagedCopied ? 'var(--core-jade)' : 'inherit'} />}
                appearance="transparent"
                onClick={copyOnClick}
            />
        </Tooltip>
    );
};

export default React.memo(CopyButton);

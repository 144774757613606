import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import { FC } from 'react';
import { useChat } from '../../../libs/hooks';
import { IAssistant } from '../../../libs/models/Assistants';
import InternalIcon from '../../global/InternalIcon';
import assistantStyles from './AssistantsList.module.scss';

interface AssistantsListProps {
    assistants: Record<string, IAssistant>;
}

export const AssistantsList: FC<AssistantsListProps> = ({ assistants }) => {
    const { createChat } = useChat();
    
    const pinnedAssistants = Object.values(assistants).filter((a) => a.pinned);

    return (
        <div className={assistantStyles.container}>
            {pinnedAssistants.map((assistant, i) => (
                <SlButton
                    onClick={() => {
                        createChat(
                            assistant.title,
                            assistant.systemDescription,
                            assistant.serviceId,
                            assistant.responseTemperature,
                            true,
                        ).catch(console.error);
                    }}
                    className={assistantStyles['pinned-assistant']}
                    key={i}
                >
                    {/* Setting the icon to 22px is equivalent to a 24px lets-icon (lets icons have padding) */}
                    <div className={assistantStyles['icon-and-title']}>
                        <InternalIcon icon="assistant-bot" width={22} height={22} style={{ paddingLeft: '2px' }} />
                        {assistant.title}
                    </div>
                    <Icon icon="lets-icons:pin-light" width={'24px'} height={'24px'} />
                </SlButton>
            ))}
        </div>
    );
};

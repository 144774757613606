export const getFileExtension = (fileName = '') => {
    return (fileName.split('.').pop() ?? '').toLowerCase();
};

const shoelaceHasIconsFor = `aac, ai, bmp, cs, css, csv, doc, docx, exe, gif, heic, html, 
    java, jpg, js, json, jsx, key, m4p, md, mdx, mov, mp3, mp4, otf, pdf, php, png, ppt, pptx, psd, py, 
    raw, rb, sass, scss, sh, sql, svg, tiff, tsx, ttf, txt, wav, woff, xls, xlsx, xml, yml`.split(', ');

export const getIconName = (fileName = '') => {
    const extension = getFileExtension(fileName);
    return (
        (extension && extension !== 'txt' && shoelaceHasIconsFor.includes(extension) && `filetype-${extension}`) ||
        'file-earmark-text'
    );
};

export const formatFileSize = (sizeInBytes = 0) => {
    if (!sizeInBytes) return 'N/A';
    const KB = 1024;
    if (sizeInBytes < KB) return `${sizeInBytes} B`;
    const MB = 1024 * KB;
    const GB = 1024 * MB;
    const units = { KB, MB, GB };
    const unitName = (sizeInBytes > GB && 'GB') || (sizeInBytes > MB && 'MB') || 'KB';

    return `${(sizeInBytes / units[unitName]).toFixed(2)} ${unitName}`;
};

import { Table, TableBody, TableHeader, TableRow } from '@fluentui/react-components';
import * as React from 'react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';

import { useChat } from '../../libs/hooks';
import { IMetadata } from '../../libs/models/ChatMessage';
import { DocumentStatus, IDocumentWithKey } from '../../redux/features/conversations/ChatState';
import useTable, { IDocumentHandler } from './useTable';

import classes from './DocumentsTable.module.scss';
import buttonStyles from '../global/buttons.module.scss';

export interface TableItem {
    key: string;
    id: string;
    chatId: string;
    name: {
        label: string;
        icon: string;
        url?: string;
    };
    createdOn: {
        label: string;
        timestamp: number;
    };
    size: number;
    state?: DocumentStatus;
    metadata?: IMetadata;
    deleteHandler?: () => void;
    downloadHandler?: () => void;
}

export const DocumentsTable: React.FC<{ resources: IDocumentWithKey[] }> = ({ resources }) => {
    const { deleteDocument } = useChat();

    const [deletingDocuments, setDeletingDocuments] = React.useState<string[]>([]);
    const [documentToDelete, setDocumentToDelete] = React.useState<string | null>(null);
    const [confirmDeletionOpen, setConfirmDeletionOpen] = React.useState(false);

    const getDeleteHandler: IDocumentHandler = (documentId) => () => {
        setDocumentToDelete(documentId);
        setConfirmDeletionOpen(!deletingDocuments.includes(documentId));
    };
    const proceedDeletion = () => {
        if (!documentToDelete) return;
        setDeletingDocuments([...deletingDocuments, documentToDelete]);
        const [{ chatId }] = resources;
        void deleteDocument(chatId, documentToDelete).finally(() => {
            setDeletingDocuments(deletingDocuments.filter((id) => id !== documentToDelete));
        });
        cancelDeletion();
    };
    const cancelDeletion = () => {
        setDocumentToDelete(null);
        setConfirmDeletionOpen(false);
    };

    const { columns, rows } = useTable(resources, deletingDocuments, getDeleteHandler);

    return (
        <>
            <h2 className={classes.header}>List of documents</h2>
            <div className={classes['table-container']}>
                <Table aria-label="uploaded documents table" className={classes.table}>
                    <TableHeader className={classes['table-header']}>
                        <TableRow>{columns.map((column) => column.renderHeaderCell())}</TableRow>
                    </TableHeader>
                    <TableBody>
                        {rows.map((item) => (
                            <TableRow key={item.key}>{columns.map((column) => column.renderCell(item))}</TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            {!resources.length && (
                <div className={classes['documents-absence']}>
                    <img src="/no-documents.svg" alt="No documents icon" />
                    <span>No documents here</span>
                </div>
            )}
            <SlDialog
                className={classes['delete-confirmation-dialog']}
                label="Delete document"
                open={confirmDeletionOpen}
                onSlAfterHide={cancelDeletion}
            >
                <span>Should the document </span>
                <b> {resources.find(({ id }) => id === documentToDelete)?.name} </b>
                <span> be deleted?</span>
                <SlButton
                    className={buttonStyles['secondary-small']}
                    slot="footer"
                    size="small"
                    onClick={cancelDeletion}
                >
                    Cancel
                </SlButton>
                <SlButton
                    className={buttonStyles['primary-small']}
                    slot="footer"
                    size="small"
                    onClick={proceedDeletion}
                >
                    Delete
                </SlButton>
            </SlDialog>
        </>
    );
};

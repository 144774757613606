import * as React from 'react';
import { useParams } from 'react-router-dom';

import { useChat } from '../../libs/hooks';

const DocumentViewer: React.FC = () => {
    const { selectedId, documentId } = useParams();
    const { downloadDocument } = useChat('ChatWindow');

    const [mimeType, setMimeType] = React.useState('');
    const [fileURL, setFileURL] = React.useState('');

    React.useEffect(() => {
        const startDownload = async () => {
            if (!selectedId || !documentId) return;
            try {
                const blob = await downloadDocument(selectedId, documentId);
                setMimeType(blob.type);
                const url = window.URL.createObjectURL(blob);
                setFileURL(url);

                if (blob.type === 'text/plain' || blob.type === 'application/pdf' || blob.type.startsWith('image/')) {
                    window.open(url, '_self');
                }
            } catch (error) {
                console.log(error);
            }
        };
        void startDownload();
    }, []);

    if (!mimeType) return null;

    return (
        <div>
            <a href={fileURL} download>
                Download document
            </a>
        </div>
    );
};
export default DocumentViewer;

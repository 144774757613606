import React from 'react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import { useCookies } from 'react-cookie';

import classes from '../ChatInput.module.scss';
import { Constants } from '../../../Constants';

const SHOW_EXPIRATION_MESSAGE = 'SHOW_EXPIRATION_MESSAGE';
const cookieSetOptions = {
    maxAge: 60 * 60 * 24 * 365 * 10,
    path: '/',
};

const ExpirationMessage = () => {
    const [showExpirationMessage, setShowExpirationMessage] = React.useState(false);
    const [cookies, setCookie] = useCookies([SHOW_EXPIRATION_MESSAGE]);

    React.useEffect(() => {
        setShowExpirationMessage(cookies.SHOW_EXPIRATION_MESSAGE !== false);
    }, [cookies]);

    const dontShowClickHandler = () => {
        setCookie(SHOW_EXPIRATION_MESSAGE, false, cookieSetOptions);
        setShowExpirationMessage(false);
    };

    const closeHandler = () => {
        setShowExpirationMessage(false);
    };

    if (!showExpirationMessage) return null;

    return (
        <div className={classes['message-box']}>
            <div className={classes['message-box-info']}>
                <div className={classes['message-box-icon']}>
                    {/* <SlIcon name="info-circle-fill" /> */}
                    <img src="/info-icon.svg" />
                </div>

                <div className={classes['message-box-text']}>
                    To be compliant, I delete chat history after {Constants.CHAT_EXPIRATION_DAYS} days. Don&apos;t
                    worry, you will be notified.
                </div>
            </div>
            <div className={classes['message-box-actions']}>
                <SlButton className={classes['dont-show-again']} variant="default" onClick={dontShowClickHandler}>
                    Don’t show again
                </SlButton>
                <SlButton className={classes.close} variant="text" aria-label="Dismiss" onClick={closeHandler}>
                    <SlIcon name="x-lg" />
                </SlButton>
            </div>
        </div>
    );
};

export default React.memo(ExpirationMessage);

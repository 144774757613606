import { Button, Field, Spinner, Tooltip } from '@fluentui/react-components';
import { Icon } from '@iconify/react';
import SlInputElement from '@shoelace-style/shoelace/dist/components/input/input.js';
import SlTabGroup from '@shoelace-style/shoelace/dist/components/tab-group/tab-group';
import SlTextareaElement from '@shoelace-style/shoelace/dist/components/textarea/textarea.js';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlTextarea from '@shoelace-style/shoelace/dist/react/textarea';
import React, { FC, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';

import { useChat } from '../../../libs/hooks';
import { useCreationWrapperClasses } from '../../../styles/assistantCreation/creationWrappers';
import { useAHClasses } from '../../../styles/assistantHub/assHubElements';
import { useButtonClasses } from '../../../styles/global/button';
import OnboardingModal from '../../modal/OnboardingModal';
import { AssistantTutorialBodies, AssistantTutorialImages } from '../../modal/OnboardingModalData';
import './assistantDescriptionTab.scss';
import { neighborsTabsNames } from './helper';
import './tabPageWrappers.scss';

interface PurposeTabProps {
    workDescription: string;
    setWorkDescription: (workDescription: string) => void;
    promptInput: string;
    setPromptInput: (promptInput: string) => void;
    tabGroupRef: React.RefObject<SlTabGroup>;
    currentTabName: string;
}

const AssistantDescriptionTab: FC<PurposeTabProps> = ({
    setWorkDescription,
    workDescription,
    promptInput,
    setPromptInput,
    tabGroupRef,
    currentTabName,
}) => {
    const [enableTutorialAssistant, setEnableTutorialAssistant] = React.useState(false);

    // Check if onboarding is required.
    const [cookies, setCookie] = useCookies(['COMPLETED_ASSISTANT_TUTORIAL']);
    if (!cookies.COMPLETED_ASSISTANT_TUTORIAL && !enableTutorialAssistant) {
        setEnableTutorialAssistant(true);
    }

    const [isLoading, setLoading] = useState<boolean>(false);
    const [generatorInput, setGeneratorInput] = useState<string>('');
    const createPromptInputRef = useRef<SlInputElement>(null);
    const workDescriptionRef = useRef<SlTextareaElement>(null);
    const { getWorkDescription } = useChat('AssistantDescriptionTab');

    React.useEffect(() => {
        if (generatorInput == '') setGeneratorInput(promptInput);
    }, [promptInput]);

    const onNext = () => {
        const { next } = neighborsTabsNames[currentTabName];
        tabGroupRef.current?.show(next);
    };

    const onGenerate = () => {
        setLoading(true);
        const prompt: string =
            "I am an expert in something, but I have amnesia. My role as an expert is very important. My job is to answer any questions people have, by offering my expertise. I don't know the details of my expertise, I only have a query to build on. When I give you the query, I want you to describe my role to me as an expert. Keep it short and to the point, and write it in one paragraph without line breaks. Again, I have woken up from a coma, and I have amnesia. How would you describe my role to me, so that I can remember again? Do this based on the following query: " +
            generatorInput;
        getWorkDescription(prompt)
            .then((response) => {
                setWorkDescription(response);
            })
            .catch((error) => {
                console.error('Error generating work description', error);
            })
            .finally(() => {
                setLoading(false);
                workDescriptionRef.current?.focus();
            });
    };

    // When the user modifies the work description field
    const onChange = (event: CustomEvent) => {
        const target = event.target as HTMLInputElement;
        setWorkDescription(target.value);
    };

    // When the user modifies the "generate description" field
    const onGenChange = (event: CustomEvent) => {
        const target = event.target as HTMLInputElement;
        setGeneratorInput(target.value);
        setPromptInput(target.value);
    };

    const AHClasses = useAHClasses();
    const wrapperclasses = useCreationWrapperClasses();
    const buttonClasses = useButtonClasses();

    return (
        <div className="tab-wrapper">
            <div className="content-wrapper">
                <div>
                    <div className="header-wrapper" style={{ marginBottom: '16px' }}>
                        <h2 style={{ margin: 0 }}>Create a prompt</h2>
                        <Tooltip
                            withArrow
                            content={
                                'A prompt is a way to guide the conversation and provide specific instructions to get the desired response from your assistant.'
                            }
                            relationship={'description'}
                            aria-label="prompt tip"
                        >
                            <Icon
                                icon="lets-icons:info-light"
                                style={{ width: '20px', height: '20px', strokeWidth: '1.3' }}
                            />
                        </Tooltip>
                    </div>

                    <p className={AHClasses.description} style={{ marginBottom: '16px', marginTop: 0 }}>
                        This is where you give instructions to your assistant. It will remember them everytime you start
                        a new chat.
                        <br></br>
                        If you&apos;re new to writing prompts, or just want to save time, we recommend using the Prompt
                        generator.
                    </p>
                    <Field style={{ marginBottom: '32px' }}>
                        <div className={wrapperclasses.promptGeneratorWrapper}>
                            <div style={{ display: 'flex', gap: '4px', marginBottom: '8px' }}>
                                <img src="/yellow-lightning.svg" width="24px" height="24px" />
                                <h4 style={{ fontSize: '16px' }}>Prompt generator</h4>
                            </div>
                            <p>
                                Writing prompts is an art, so let&apos;s help you get started! <br></br>Describe how you
                                want your assistant to support you, in a single sentence.
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '16px',
                                }}
                            >
                                <SlInput
                                    ref={createPromptInputRef}
                                    autoFocus
                                    style={{ marginRight: '20px', width: '100%' }}
                                    value={generatorInput}
                                    onSlInput={onGenChange}
                                    className={'generator-input'}
                                    placeholder="As a project manager, I need help to create documentation"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onGenerate();
                                        }
                                    }}
                                />
                                <Button
                                    disabled={generatorInput.length !== 0 && !isLoading ? false : true}
                                    className={buttonClasses.primaryButtonLarge}
                                    onClick={onGenerate}
                                    style={{ height: '50px', width: 'max-content', flexShrink: '0' }}
                                >
                                    {isLoading ? (
                                        <div style={{ display: 'flex' }}>
                                            <Spinner size="tiny" />
                                            <span style={{ marginLeft: '8px' }}>Creating draft..</span>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', gap: '4px' }}>
                                            <Icon icon="lets-icons:lightning-light" width={24} height={24} />
                                            <span style={{ whiteSpace: 'nowrap' }}>Generate prompt</span>
                                        </div>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Field>
                    <h4 style={{ margin: '0', marginBottom: '8px' }}>Your prompt</h4>
                    <p className={AHClasses.description} style={{ marginBottom: '16px' }}>
                        Tell your assistant how to help you. Edit until you&apos;re happy. Come back to make changes any
                        time.
                    </p>

                    <SlTextarea
                        value={workDescription}
                        placeholder="As an expert, your role is to..."
                        disabled={isLoading}
                        onSlChange={onChange}
                        ref={workDescriptionRef}
                        style={{ marginBottom: '16px' }}
                        className="workdescription"
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button className={buttonClasses.primaryButtonLarge} onClick={onNext}>
                        Continue
                    </Button>
                </div>
            </div>
            <OnboardingModal
                isOpen={enableTutorialAssistant}
                onClose={() => {
                    setCookie('COMPLETED_ASSISTANT_TUTORIAL', true, { maxAge: 60 * 60 * 24 * 365 * 10, path: '/' });
                    setEnableTutorialAssistant(false);
                }}
                bodies={AssistantTutorialBodies}
                images={AssistantTutorialImages}
                slideNr={0}
            />
        </div>
    );
};

export default AssistantDescriptionTab;

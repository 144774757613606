import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import React, { useRef } from 'react';

// Rest of the code
import { useAssistants } from '../../libs/hooks/useAssistants';
import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import { useActiveAssistantsSelector, useAssistantByIdSelector } from '../../redux/app/hooks';
import InternalIcon from '../global/InternalIcon';
import tooltipStyles from '../global/tooltip.module.scss';
import { PopUpDialog } from '../popups/PopUpDialog';
import assistantlistStyles from './AssistantListItem.module.scss';
import { Constants } from '../../Constants';

export type TCreateNewChat = (
    name: string,
    systemDescription?: string,
    serviceId?: string,
    responseTemperature?: number,
    isAssistant?: boolean,
) => void;

interface IAssistantListItemProps {
    assistantId: string;
    handleNewChat: TCreateNewChat;
    disabled?: boolean;
}

const AssistantListItem: React.FC<IAssistantListItemProps> = ({ assistantId, handleNewChat, disabled }) => {
    const dropdownTimer = useRef<NodeJS.Timeout | null>(null);
    const { deleteAssistantAsync, editAssistantAsync } = useAssistants('AssistantListItem');
    const { goEditAssistant } = useGoPage();
    const assistant = useAssistantByIdSelector(assistantId);
    const activeAssistants = useActiveAssistantsSelector();
    const pinnedAssistants = activeAssistants.filter((assistant) => assistant.pinned);
    const isPinnedAssistantsLimitReached = pinnedAssistants.length >= Constants.PINNED_ASSISTANTS_LIMIT;

    const [isPoppingOver, setIsPoppingOver] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleDropdownMouseEneter = () => {
        dropdownTimer.current && clearTimeout(dropdownTimer.current);
        setIsPoppingOver(true);
    };

    const closePopOver = () => {
        setIsPoppingOver(false);
        setIsOpen(false);
    };

    const handleDropdownMouseLeave = () => {
        dropdownTimer.current = setTimeout(closePopOver, 500);
    };

    const handleClick = () => {
        if (!isPoppingOver) {
            handleNewChat(
                assistant.title,
                assistant.systemDescription,
                assistant.serviceId,
                assistant.responseTemperature,
                true,
            );
        }
    };

    const handleEdit = () => {
        goEditAssistant(assistant.id);
        setIsPoppingOver(false);
    };

    const handleDelete = () => {
        void deleteAssistantAsync(assistant.id);
        closePopOver();
    };

    const handleHide = () => {
        void editAssistantAsync(
            assistant.title,
            assistant.systemDescription,
            assistant.responseTemperature,
            assistant.id,
            false,
            false, //assistant.pinned,
            assistant.serviceId,
        );
        closePopOver();
    };

    const handlePin = () => {
        if (isPinnedAssistantsLimitReached && !assistant.pinned) return;
        void editAssistantAsync(
            assistant.title,
            assistant.systemDescription,
            assistant.responseTemperature,
            assistant.id,
            assistant.active,
            !assistant.pinned,
            assistant.serviceId,
        );
        closePopOver();
    };

    const popOverButton = (contentText = '', contentIcon = '', handleButtonClick = () => {}, tooltip = '') => {
        const AssistantActionButton: React.FC = () => (
            <SlButton className={assistantlistStyles['menu-item']} onClick={handleButtonClick} variant="text">
                <div className={assistantlistStyles['assistant-icon-container']}>
                    {contentIcon === 'unpin' ? (
                        <InternalIcon icon={'unpin-light'} width={24} height={24} style={{ marginRight: '8px' }} />
                    ) : (
                        <Icon
                            icon={'lets-icons:' + contentIcon + '-light'}
                            width={24}
                            height={24}
                            style={{ marginRight: '8px' }}
                        />
                    )}
                    {contentText}
                </div>
            </SlButton>
        );
        return tooltip ? (
            <SlTooltip hoist content={tooltip} placement="bottom" className={tooltipStyles['sapience-tooltip']}>
                <AssistantActionButton />
            </SlTooltip>
        ) : (
            <AssistantActionButton />
        );
    };

    return (
        <div className={assistantlistStyles['assistant-item']}>
            <SlButton
                className={assistantlistStyles['start-chat-button']}
                onClick={handleClick}
                disabled={disabled}
                variant="text"
            >
                <div slot="prefix" style={{ display: 'flex' }}>
                    <InternalIcon icon="assistant-bot" width={24} height={24} style={{}} />
                </div>
                <span>{assistant.title}</span>
                {assistant.pinned && <Icon icon="lets-icons:pin-light" width={18} height={18} />}
            </SlButton>
            <SlTooltip
                content={'Edit ' + assistant.title}
                placement="right"
                className={tooltipStyles['sapience-tooltip']}
            >
                <SlDropdown
                    onMouseEnter={handleDropdownMouseEneter}
                    onMouseLeave={handleDropdownMouseLeave}
                    onSlShow={() => {
                        setIsOpen(true);
                    }}
                    placement="bottom-end"
                    open={isOpen}
                >
                    <SlButton slot="trigger" variant="text" className={assistantlistStyles['meatball-button']}>
                        <Icon icon="lets-icons:meatballs-menu" width={24} height={24} />
                    </SlButton>
                    <SlMenu className={assistantlistStyles['assistant-menu']}>
                        {popOverButton(
                            assistant.pinned ? 'Unpin' : 'Pin',
                            assistant.pinned ? 'unpin' : 'pin',
                            handlePin,
                            isPinnedAssistantsLimitReached && !assistant.pinned
                                ? `You can pin up to ${Constants.PINNED_ASSISTANTS_LIMIT} assistants. Please unpin one to pin a new assistant.`
                                : undefined,
                        )}
                        {popOverButton('Edit', 'edit', handleEdit)}
                        {popOverButton('Hide', 'view-hide', handleHide)}
                        <PopUpDialog
                            header="Delete assistant"
                            content={`Are you sure you want to delete this assistant: ${assistant.title}?`}
                            buttonText="Delete"
                            onAction={handleDelete}
                            canHide={false}
                        >
                            {popOverButton('Delete', 'trash')}
                        </PopUpDialog>
                    </SlMenu>
                </SlDropdown>
            </SlTooltip>
        </div>
    );
};

export default AssistantListItem;

import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import { FC } from 'react';
import { useGoPage } from '../../../libs/hooks/useRouterNavigate';
import { ChatState } from '../../../redux/features/conversations/ChatState';
import InternalIcon from '../../global/InternalIcon';
import chatsStyles from './Chatslist.module.scss';

interface ChatsListProps {
    conversations: ChatState[];
}

export const ChatsList: FC<ChatsListProps> = ({ conversations }) => {
    const { goChat } = useGoPage();
    const lastConversations = conversations.slice(0, 3);
    if (lastConversations.length === 0)
        return (
            <div className={chatsStyles['no-chats-card']}>
                <p>
                    No chats available. <br />
                    You can start a chat above.
                </p>
            </div>
        );
    return (
        <div className={chatsStyles['chats-list']}>
            {lastConversations.map((conversation, index) => (
                <SlButton
                    onClick={() => {
                        goChat(conversation.id);
                    }}
                    className={chatsStyles['grid-button']}
                    key={index}
                >
                    <div className={chatsStyles['icon-name-container']}>
                        {conversation.isAssistant ? (
                            // Setting the icon to 22px is equivalent to a 24px lets-icon (lets icons have padding)
                            <InternalIcon
                                icon="assistant-bot"
                                width={22}
                                height={22}
                                style={{ marginRight: '12px', paddingLeft: '2px' }}
                            />
                        ) : (
                            <Icon icon="lets-icons:chat-light" className={chatsStyles['chat-icon']} />
                        )}
                        {conversation.title + ': '}
                        <div className={chatsStyles['last-message']}>{conversation.lastMessage}</div>
                    </div>
                </SlButton>
            ))}
        </div>
    );
};

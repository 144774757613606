import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlSwitch from '@shoelace-style/shoelace/dist/react/switch';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import React from 'react';

import { useAssistants } from '../../libs/hooks/useAssistants';
import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import { IAssistant } from '../../libs/models/Assistants';
import { useAssistantByIdSelector } from '../../redux/app/hooks';
import { PopUpDialog } from '../popups/PopUpDialog';

import { useChat } from '../../libs/hooks';
import InternalIcon from '../global/InternalIcon';
import tooltipstyles from '../global/tooltip.module.scss';
import styles from './PAButton.module.scss';
import { Constants } from '../../Constants';

export const CreatePAButton: React.FC = () => {
    const { goCreateAssistant } = useGoPage();

    return (
        <div
            aria-label="Create New Assistant Button"
            id="newAssistantButton"
            className={styles.container}
            onClick={goCreateAssistant}
        >
            <img src={'/add.svg'} className={styles.icon} />
            <span className={styles.textContainer}>Create an assistant</span>
        </div>
    );
};

interface PAItemProps {
    assistantId: string;
    isPinnedAssistantsLimitReached?: boolean;
}

export const PAItem: React.FC<PAItemProps> = ({ assistantId, isPinnedAssistantsLimitReached }) => {
    const { deleteAssistantAsync, editAssistantAsync } = useAssistants('PAItem');
    const assistant: IAssistant = useAssistantByIdSelector(assistantId);
    const { goEditAssistant } = useGoPage();
    const chat = useChat();

    const handleActivate = () => {
        editAssistantAsync(
            assistant.title,
            assistant.systemDescription,
            assistant.responseTemperature,
            assistantId,
            !(assistant.active as boolean),
            false, //assistant.pinned,
            assistant.serviceId,
        ).catch((e: Error) => {
            console.error(e);
        });
    };

    const createChat = (assistant: IAssistant) => {
        chat.createChat(
            assistant.title,
            assistant.systemDescription,
            assistant.serviceId,
            assistant.responseTemperature,
            true,
        ).catch((e) => {
            console.error(e);
        });
    };

    const handlePin = () => {
        if (isPinnedAssistantsLimitReached && !assistant.pinned) return;
        editAssistantAsync(
            assistant.title,
            assistant.systemDescription,
            assistant.responseTemperature,
            assistantId,
            true, // Set assistant as active
            !assistant.pinned,
            assistant.serviceId,
        ).catch((e: Error) => {
            console.error(e);
        });
    };

    const handleEdit = () => {
        goEditAssistant(assistantId);
    };

    const handleDelete = () => {
        deleteAssistantAsync(assistantId).catch((e: Error) => {
            console.error(e);
        });
    };

    return (
        <div
            aria-label={`Personal assistant ${assistant.title}`}
            className={assistant.active ? styles.assistant : styles.inactiveAssistant}
            style={{ backgroundColor: assistant.pinned ? 'var(--extended-grass-in-bright-light)' : '' }}
        >
            {' '}
            <div className={styles['switch-and-title']}>
                <SlSwitch
                    aria-label="Switch assistant"
                    checked={assistant.active}
                    onSlChange={handleActivate}
                    className={styles.switch}
                />
                <span>{assistant.title}</span>
                {assistant.pinned && <Icon icon="lets-icons:pin-fill" width={18} height={18} />}
            </div>
            <div className={assistant.pinned ? styles['actions-pinned'] : styles.actions}>
                {/* --- Create new chat --- */}
                <SlTooltip content={'Start chat'} className={tooltipstyles['sapience-tooltip']}>
                    <SlButton
                        variant="text"
                        className={styles['action-button']}
                        onClick={() => {
                            createChat(assistant);
                        }}
                    >
                        <InternalIcon icon="add-chat-plus" width={20} height={20} style={{ paddingLeft: '2px' }} />
                    </SlButton>
                </SlTooltip>

                {/* --- Pin assistant --- */}
                <SlTooltip
                    content={
                        (assistant.pinned && 'unpin') ||
                        (isPinnedAssistantsLimitReached &&
                            !assistant.pinned &&
                            `You can pin up to ${Constants.PINNED_ASSISTANTS_LIMIT} assistants. Please unpin one to pin a new assistant.`) ||
                        'pin'
                    }
                    className={tooltipstyles['sapience-tooltip']}
                >
                    <SlButton onClick={handlePin} variant="text" className={styles['action-button']}>
                        {assistant.pinned ? (
                            <InternalIcon icon="unpin-light" height={20} width={20} />
                        ) : (
                            <Icon icon="lets-icons:pin-light" height={20} width={20} />
                        )}
                    </SlButton>
                </SlTooltip>

                {/* --- Edit assistant--- */}
                <SlTooltip content={'edit assistant'} className={tooltipstyles['sapience-tooltip']}>
                    <SlButton onClick={handleEdit} variant="text" className={styles['action-button']}>
                        <Icon icon="lets-icons:edit-light" height="20px" width="20px" />
                    </SlButton>
                </SlTooltip>

                {/* --- Delete assistant --- */}
                <PopUpDialog
                    header="Delete assistant"
                    content={`Are you sure you want to delete this assistant: ${assistant.title}?`}
                    buttonText="Delete"
                    onAction={handleDelete}
                    canHide={false}
                >
                    <SlTooltip content={'delete assistant'} className={tooltipstyles['sapience-tooltip']}>
                        <SlButton variant="text" className={styles['action-button']}>
                            <Icon icon="lets-icons:trash-light" height="20px" width="20px" />
                        </SlButton>
                    </SlTooltip>
                </PopUpDialog>
            </div>
        </div>
    );
};

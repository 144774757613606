import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import Alerts from './Alerts';
import { SideBar } from '../sidebar/Sidebar';

import classes from './ChatView.module.scss';

export const ChatView: FC = () => {
    // Check if onboarding is required.
    const { isAssistantCreateRoute, isAssistantEditRoute } = useGoPage();

    return (
        <div className={classes.container}>
            {!isAssistantCreateRoute && !isAssistantEditRoute && <SideBar />}
            <Outlet />
            <Alerts />
        </div>
    );
};

import React from 'react';
import { Outlet } from 'react-router-dom';
import creatorclasses from './AssistantCreator.module.scss';

import PACreation from '../chat/personal-assistant/PACreation';

const AssistantCreator: React.FC = () => {
    return (
        <div className={creatorclasses['creator-wrapper']}>
            <PACreation />
            <Outlet />
        </div>
    );
};

export default AssistantCreator;

import { Button, Tooltip } from '@fluentui/react-components';
import { Icon } from '@iconify/react';
import React from 'react';

import { useChat } from '../../../libs/hooks';
import { ChatMessageType } from '../../../libs/models/ChatMessage';
import { ChatState } from '../../../redux/features/conversations/ChatState';

interface RegenerateButtonProps {
    question?: string;
    conversationWithoutInput: ChatState; // we don't want to rerender every time input changed
}

const RegenerateButton: React.FC<RegenerateButtonProps> = ({ question, conversationWithoutInput }) => {
    const { getResponse } = useChat('ChatHistoryItem');

    const reGenerateAnswer = () => {
        if (!question) return;
        getResponse({
            messageType: ChatMessageType.Message,
            value: question,
            chatId: conversationWithoutInput.id,
            serviceId: conversationWithoutInput.serviceId,
            chat: { ...conversationWithoutInput, responseTemperature: 0.9 },
        }).catch((error) => {
            if (process.env.NODE_ENV === 'development') {
                console.log(error);
            }
        });
    };

    return (
        <Tooltip content={'Regenerate answer'} relationship="label">
            <Button
                icon={<Icon icon="lets-icons:box-refresh-right-light" />}
                appearance="transparent"
                onClick={reGenerateAnswer}
                disabled={!question}
            />
        </Tooltip>
    );
};

export default React.memo(RegenerateButton);

import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SlRadioGroup from '@shoelace-style/shoelace/dist/react/radio-group';
import SlRadioButton from '@shoelace-style/shoelace/dist/react/radio-button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import classes from './TagSelector.module.scss';
import { useChat } from '../../libs/hooks';
import { IMetadata } from '../../libs/models/ChatMessage';
import { updateConversationDocumentMetadata } from '../../redux/features/conversations/conversationsSlice';

const options = [
    { label: 'Customer', buttonClassName: 'button-first' },
    { label: 'Internal', buttonClassName: 'button-last' },
];

interface TagSelectorProps {
    chatId: string;
    documentId: string;
    metadata: IMetadata;
}

const TagSelector: FC<TagSelectorProps> = ({ metadata, chatId = '', documentId = '' }) => {
    const { patchMetadata } = useChat();
    const dispatch = useDispatch();

    const [isInternal, setIsInternal] = useState(true);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setDisabled(!chatId || !documentId);
    }, [chatId, documentId]);

    useEffect(() => {
        setIsInternal(metadata?.isInternal === 'true');
    }, [metadata]);

    const handleChange = () => {
        setDisabled(true);
        if (!chatId || !documentId) return;
        const newMetadata = { ...metadata, isInternal: String(!isInternal) };
        const documentsMetadata = [
            {
                id: documentId,
                metadata: newMetadata,
            },
        ];
        void patchMetadata(chatId, documentsMetadata)
            .then(() => {
                dispatch(
                    updateConversationDocumentMetadata({
                        chatId,
                        documentId,
                        metadataUpdate: { isInternal: String(!isInternal) },
                    }),
                );
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setDisabled(false);
            });
    };

    return (
        <SlRadioGroup
            size="small"
            value={String(isInternal)}
            onSlChange={handleChange}
            className={classes['radio-group']}
        >
            {options.map((option, i) => (
                <SlRadioButton
                    key={option.buttonClassName}
                    className={classes[option.buttonClassName]}
                    pill
                    value={String(Boolean(i))}
                    disabled={disabled}
                >
                    {isInternal === Boolean(i) ? (
                        <div slot="prefix" className={classes['icon-container-checked']}>
                            <SlIcon name="check" />
                        </div>
                    ) : (
                        <div slot="prefix" className={classes['icon-container']}></div>
                    )}
                    {option.label}
                </SlRadioButton>
            ))}
        </SlRadioGroup>
    );
};

export default TagSelector;

import React from 'react';

import { IAssistant } from '../../libs/models/Assistants';
import { useActiveAssistantsSelector } from '../../redux/app/hooks';
import AssistantListItem, { TCreateNewChat } from './AssistantListItem';

import styles from './Sidebar.module.scss';

const assistantSorterByDate = (a: IAssistant, b: IAssistant) => {
    if (!a.createdOn || !b.createdOn || a.createdOn === b.createdOn) return 0;
    return a.createdOn > b.createdOn ? -1 : 1;
};

interface IAssistantListProps {
    creatingNewChat: boolean;
    handleNewChat: TCreateNewChat;
}
const AssistantList: React.FC<IAssistantListProps> = ({ creatingNewChat, handleNewChat }) => {
    const activeAssistants = useActiveAssistantsSelector();

    // Sorting first by pinned status, then by date
    const assistantsIds = structuredClone(activeAssistants)
        .sort((a, b) => {
            if (a.pinned && !b.pinned) return -1;
            if (!a.pinned && b.pinned) return 1;
            return assistantSorterByDate(a, b);
        })
        .map((a) => a.id);

    return (
        <div aria-label="Assistants list" className={styles['assistant-list']}>
            {activeAssistants.length ? (
                assistantsIds.map((assistantId) => (
                    <AssistantListItem
                        assistantId={assistantId}
                        key={assistantId}
                        disabled={creatingNewChat}
                        handleNewChat={handleNewChat}
                    />
                ))
            ) : (
                <p className={styles['empty-text']}>Your assistants will appear here.</p>
            )}
        </div>
    );
};

export default React.memo(AssistantList);
